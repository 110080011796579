/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    blockquote: "blockquote",
    strong: "strong",
    a: "a",
    em: "em",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/beeb83789b089f22aca92161d663cc82/topologias_de_red.gif",
    alt: ""
  })), "\n", React.createElement(_components.p, null, "Un error de concepto que leo una y otra vez a lo largo de la red es la confusión entre red distribuida y red de malla completa, lo cierto es que están relacionadas estas dos redes, pero no son lo mismo, vamos a verlo."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Red distribuida"), ": La extracción de cualquiera de los nodos no desconectaría de la red a ningún otro. Todos los nodos se conectan entre si sin que tengan que pasar necesariamente por uno o varios centros locales. En este tipo de redes desaparece la división centro periferia y por tanto el poder de filtro sobre la información que fluye por ella."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://lasindias.com/indianopedia/topologias-de-red"
  }, "Indianopedia")), "\n"), "\n", React.createElement(_components.p, null, "Partiendo de esta definición básica del concepto de red distribuida podemos ver que estas redes coinciden con la definición de red de malla o redes ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Mesh_networking"
  }, React.createElement(_components.em, null, "mesh")), ". Las redes de malla se distinguen en dos tipos: completamente conectadas o parcialmente conectadas."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 399px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/2d052fcce1f9e5ae888a22a046da3772/a307d/malla-completa-parcial.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 33.54430379746836%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAApkAAAKZAHRdi/TAAAAyklEQVQoz2WS204CQRBEVy5BhF0QIyBi9EFI/Ar//4/2nCfSpicZh4dOT9LVVdWV6dSuFFDeT+qlnlW1VefqBOiBWeyV6hrCAC3VtXrKxZbwoC7UJfAOPBcjNeFE7cNZks6DGNi1hMCQtQcWwBnYtg5D8Qc4pmIon9XfANeRAF8ptgm8+hizcRz/MK36VB3UIAzHM3Va59v0UyV0l2GfbiKjzwx8yPPrpY36Aqwy63+YmnAfZ6uvefIV+K7yecj+pl7Vj/wJIb4uhDcdOJ61IjIG0QAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"malla completa-parcial\"\n        title=\"malla completa-parcial\"\n        src=\"/static/2d052fcce1f9e5ae888a22a046da3772/a307d/malla-completa-parcial.png\"\n        srcset=\"/static/2d052fcce1f9e5ae888a22a046da3772/c26ae/malla-completa-parcial.png 158w,\n/static/2d052fcce1f9e5ae888a22a046da3772/6bdcf/malla-completa-parcial.png 315w,\n/static/2d052fcce1f9e5ae888a22a046da3772/a307d/malla-completa-parcial.png 399w\"\n        sizes=\"(max-width: 399px) 100vw, 399px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "En las primeras, todos los nodos tienen una conexión directa con otros nodos por lo tanto la comunicación puede ser siempre directa, no es necesario el enrutado de la información. En las segundas, las más abundantes, cada nodo está comunicado con varios nodos, pero no todos, la información circula por toda la red porque los nodos son más o menos permisivos en el traspaso de información, es decir que existe un protocolo de enrutado, en redes como GNU social, que es de este tipo, a este protocolo le llamamos federación y su especificación es OStatus."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
